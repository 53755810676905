<template lang="pug">
  .flex.flex-row.h-full
    .col-span-1.flex-1
      companies-table(@ctxClick="contextMenuClicked", height="300px", :actionBar="false", @search="searchText = $event")
        template(slot="buttons")
          app-button(@click="$router.push('/companies/new')", primary) Add company
</template>

<script>
import setCompanyID from "@/utils/SetCompany";
import CompaniesTable from "@/components/tables/CompaniesTable.vue";
import DeleteCompanyMutation from "@/graphql/mutations/companies/DeleteCompanyMutation.gql";
import "whatwg-fetch";
import CompaniesQuery from "@/graphql/queries/Companies.gql";

export default {
  components: {
    CompaniesTable,
  },
  data() {
    return {
      searchText: "",
    };
  },
  methods: {
    contextMenuClicked(action, company) {
      switch (action) {
        case "loginAsCompany":
          this.newTab(company.id);
          break;

        case "viewCompany":
          setCompanyID(company.id).then(() => {
            this.$router.push(`/companies/${company.id}`);
          });
          break;

        case "viewOnZoho":
          window.open(
            `https://crm.zoho.eu/crm/tab/Accounts/${company.zohoCompanyId}`,
            "_blank"
          );
          break;

        case "deleteCompany":
          this.deleteCompany(company.id);
          break;
      }
    },
    deleteCompany(companyId) {
      const confirmed = confirm(
        "Are you sure you want to delete this company? This cannot be undone"
      );

      if (confirmed) {
        const variables = {
          searchText: this.searchText,
        };

        this.$apollo
          .mutate({
            mutation: DeleteCompanyMutation,
            variables: {
              input: {
                company: {
                  id: companyId,
                },
              },
            },
            update(store, { data: { deleteCompany } }) {
              if (deleteCompany.company.id) {
                const data = store.readQuery({
                  query: CompaniesQuery,
                  variables,
                });

                data.saCompanies.edges = data.saCompanies.edges.filter(edge => {
                  return edge.node.id !== deleteCompany.company.id;
                });

                store.writeQuery({
                  query: CompaniesQuery,
                  variables,
                  data,
                });
              }
            },
          })
          .then(() => {
            this.$flash.success("Company successfully deleted");
          });
      }
    },
    newTab(companyId) {
      setCompanyID(companyId).then(() => {
        window.open(process.env.VUE_APP_MP_HOST, "_blank");
      });
    },
  },
};
</script>
