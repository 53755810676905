import "whatwg-fetch";

export default id => {
  const host = process.env.VUE_APP_API_HOST;
  let url = "superadmin/set_company_id";
  if (host) {
    url = `${host}/${url}`;
  }

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ company_id: id }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(res => res.text());
};
