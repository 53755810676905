<script>
import Vue from "vue";
import { format, parseISO, differenceInCalendarDays } from "date-fns";
import { Types } from "manageplaces-ui-kit";
import { Components } from "manageplaces-ui-kit";

export default Vue.extend({
  computed: {
    formattedDate() {
      let date = this.params.value;
      let formatString = this.$store.state.dateFormats.dateFns;
      formatString = `${formatString} HH:mm`;
      date = parseISO(date);
      return format(date, formatString);
    },
  },
  render(h) {
    if (!this.params.value || this.params.value === "") {
      return h(Components.AppLabel, { props: { colour: Types.labels.GREY } }, "No activity");
    }
    const activityDate = new Date(this.params.value);
    const currentDate = new Date();
    const diff = differenceInCalendarDays(currentDate, activityDate);
      if(diff <= 2) {
        return h(Components.AppLabel, { props: { colour: Types.labels.BLUE } }, this.formattedDate);
      }
      else if(diff <= 7) {
        return h(Components.AppLabel, { props: { colour: Types.labels.GREEN } }, this.formattedDate);
      }
      else if(diff <= 14) {
        return h(Components.AppLabel, { props: { colour: Types.labels.YELLOW } }, this.formattedDate);
      }
      else {
        return h(Components.AppLabel, { props: { colour: Types.labels.RED } }, this.formattedDate);
      }
  },
});
</script>
