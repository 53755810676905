import { apolloClient } from "@/vue-apollo";
import CompaniesQuery from "@/graphql/queries/Companies.gql";

export default class CompaniesDataSource {
  constructor() {
    this.rowCount = 0;
  }
  getRows(params) {
    const start = params.startRow;
    const endRow = params.endRow;
    const searchText = params.filterModel?.name?.filter;
    const sortColumn = params.sortModel[0]?.colId;
    const sortDirection = params.sortModel[0]?.sort;

    apolloClient
      .query({
        query: CompaniesQuery,
        variables: {
          offset: start,
          first: endRow - start,
          searchText,
          sortColumn,
          sortDirection,
        },
      })
      .then(result => {
        const companies = result.data.saCompanies.edges.map(edge => edge.node);
        params.successCallback(companies, start + companies.length);
      });
  }
}
