<script>
import { Components, Helpers } from "manageplaces-ui-kit";
import LastActiveCellRenderer from "@/components/cell_renderers/LastActiveCellRenderer.vue";
import CompaniesDataSource from "@/components/tables/CompaniesDataSource.js";

export default {
  extends: Components.BaseTable,
  data() {
    const vm = this;
    return {
      components: {
        vue: {
          lastActive: LastActiveCellRenderer,
        },
      },
      columns: [
        {
          headerName: "Name",
          field: "name",
          flex: 1,
          sortable: true,
          cellRenderer: "link",
          cellRendererParams: {
            onClick(company) {
              vm.$emit("ctxClick", "viewCompany", company);
            },
          },
        },
        {
          headerName: "Status",
          field: "subscription.status",
          sortable: true,
          width: 150,
        },
        {
          headerName: "Plan",
          field: "subscription.plan.name",
          sortable: true,
          width: 150,
        },
        {
          headerName: "Start Date",
          field: "subscription.startDate",
          sortable: true,
          maxWidth: 120,
          cellRenderer: "date",
        },
        {
          headerName: "End Date",
          field: "subscription.endDate",
          sortable: true,
          maxWidth: 120,
          cellRenderer: "date",
        },
        {
          headerName: "Users",
          field: "userCount",
          maxWidth: 100,
          sortable: true,
          comparator: (num1, num2) => num1 - num2,
        },
        {
          headerName: "Projects",
          field: "projects",
          maxWidth: 100,
          sortable: true,
          comparator: (num1, num2) => num1 - num2,
        },
        {
          headerName: "Tasks",
          field: "tasks",
          maxWidth: 100,
          sortable: true,
          comparator: (num1, num2) => num1 - num2,
        },
        {
          headerName: "Created",
          field: "createdAt",
          sortable: true,
          maxWidth: 120,
          cellRenderer: "date",
        },
        {
          headerName: "Last activity",
          field: "lastActivity",
          sortable: false,
          cellRenderer: "lastActive",
        },
        Helpers.table.actionsCell(),
      ],
      companies: [],
      searchText: "",
      config: {
        datasource: {
          rowModelType: "infinite",
        },
      },
      datasource: new CompaniesDataSource(this),
    };
  },

  methods: {
    getContextMenuItems(company) {
      let actionMenuItems = [];
      actionMenuItems.push(
        {
          label: "View",
          action: "viewCompany",
        },
        {
          label: "Login as company",
          action: "loginAsCompany",
        },
        {
          label: "Delete",
          action: "deleteCompany",
          icon: "delete",
          iconColour: "var(--withered-cherry)",
        }
      );
      if (company.zohoCompanyId) {
        actionMenuItems.push({
          label: "View on Zoho",
          action: "viewOnZoho",
        });
      }
      return actionMenuItems;
    },
    contextMenuItemClicked(item, row) {
      const company = row.data;
      this.$emit("ctxClick", item.action, company);
    },
    performSearch(searchText) {
      const filter = {
        name: {
          type: "equals",
          filter: searchText,
          filterType: "text",
        },
      };
      this.gridApi.setFilterModel(filter);
    },
  },
};
</script>
